@layer base {
  html {
    @apply font-normal text-base antialiased min-h-full bg-primary font-sans;
  }
  body {
    @apply min-h-full bg-primary;
  }
}

@layer utilities {
  .number-input-no-spin::-webkit-outer-spin-button,
  .number-input-no-spin::-webkit-inner-spin-button {
    display: none;
  }

  .date-time-no-icon::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  /* Hide scrollbar while maintaining functionality */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}
